import React from "react";
import Layout from "../components/Layout";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

const DonatePage = () => {
  return (
    <Layout>
      <div className="row py-5">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <h2>Donate to LAAS</h2>
          <p>
            In addition to our{" "}
            <a
              href="http://stores.clothes2order.com/laaswear/"
              target="_blank"
              rel="noopener noreferrer"
            >
              shop<i className="fas fa-external-link-alt pl-1"></i>
            </a>
            , you can also make a donation directly to Labour Against
            Antisemitism and help us keep fighting
          </p>
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_blank"
            className="text-center pt-2"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="76TVGR6VXGVFN"
            />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </div>
      </div>
      <ThreeLinkBlock />
    </Layout>
  );
};

export default DonatePage;
